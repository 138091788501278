.property-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .property-image {
    position: relative;
    width: 30%;
    height: 400px;
    text-align: center;
  }
  
  .property-summary {
    flex: 1;
    margin-left: 20px;
  }
  
  /* Media Query for Responsive Layout */
  @media (max-width: 768px) {
    .property-container {
      flex-direction: column; /* Stack items vertically */
      align-items: center;
    }
  
    .property-image {
      width: 90%; /* Increase image width */
      height: auto; /* Allow height to adjust proportionally */
    }
  
    .property-summary {
      margin-left: 0; /* Remove left margin */
      margin-top: 20px; /* Add spacing between image and summary */
      text-align: center; /* Center-align text */
    }
  }
  